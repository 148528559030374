<template>
  <div class="comment">
    <div class="bgc" v-show="BqShow" @click="onClose"></div>
    <div class="user-comment">
      <div class="logo-box">
        <img
          class="logo-img"
          :src="
            userInfo.headimgurl ||
            'https://eduapi.114kaili.com/Content/images/no_headimgurl.png'
          "
          alt=""
        />
      </div>

      <div class="right-input-box" v-if="userInfo.fk_user_id > 0">
        <el-input
          id="input"
          type="textarea"
          :rows="4"
          placeholder="请输入内容"
          v-model="userVal"
        >
        </el-input>
        <div class="input-btn-box">
          <img
            class="btn-icon"
            src="@/assets/teacherTraining/ic_11.png"
            alt=""
            @click="openBQ"
          />
          <div
            class="input-btn"
            @click="onSubmitComment(1, 1, dataForm.fk_course_id, 0, 'userVal')"
          >
            发布
          </div>
          <div class="bq" v-show="BqShow">
            <VEmojiPicker @select="selectEmoji($event, 'userVal', 'input')" />
          </div>
        </div>
      </div>
      <div class="comment-input-box" v-else>
        <div
          class="login-btn"
          @click="onLogin('study_detail', $route.query.id)"
        >
          登录
        </div>
        <span class="tip">登陆后才可留言</span>
      </div>
    </div>
    <div class="comment-list">
      <div class="comment-item" v-for="(item, index) in dataList" :key="index">
        <div class="logo-box">
          <img class="logo-img" :src="item.headimgurl" alt="" />
        </div>
        <div class="item-right">
          <div class="info-box">
            <div class="user-box">
              <span class="user-name">{{ item.nickname }}</span>
              <span class="user-time">{{ item.date_creation }}</span>
            </div>
            <div class="operation-box">
              <div class="operation-comment-box" @click="onComment(index)">
                <img
                  class="operation-icon"
                  src="@/assets/teacherTraining/ic_9.png"
                  alt=""
                />
                <span>评论({{ item.comment_count }})</span>
              </div>
              <div
                class="operation-num-box"
                @click="onFabulous(1, 4, item.fk_comment_id)"
              >
                <img
                  class="operation-icon"
                  src="@/assets/teacherTraining/ic_4_fill.png"
                  alt=""
                  v-if="item.fabulous_status == 1"
                />
                <img
                  v-else
                  class="operation-icon"
                  src="@/assets/teacherTraining/ic_4.png"
                  alt=""
                />
                <span>{{ item.fabulous_count }}</span>
              </div>
            </div>
          </div>
          <div class="comment-content">{{ item.content }}</div>
          <comment-reply
            v-model="item.checked"
            :obj="item"
            :comment_id="dataForm.fk_course_id"
          />
        </div>
      </div>
      <div class="kong" v-if="dataList.length <= 0">
        <el-empty
          image="https://eduapi.114kaili.com/Content/images/no_data.png"
          :image-size="303"
          description="暂无数据"
        ></el-empty>
      </div>
    </div>
    <div class="pagination">
      <el-pagination
        @current-change="handleCurrentChange"
        :background="true"
        @prev-click="handleCurrentChange"
        @next-click="handleCurrentChange"
        :page-size="dataListParams.pageSize"
        layout="total, prev, pager, next"
        :total="dataListParams.total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import commentReply from "./comment-reply";
import detail from "@/mixins/detail.js";
export default {
  props: {
    dataForm: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    commentReply,
  },
  mixins: [detail],
  data() {
    return {
      userVal: "",
    };
  },
  watch: {
    dataForm(val) {
      if (val) {
        this.getOneComment(1, 1, this.dataForm.fk_course_id, 0);
      }
    },
  },
  methods: {
    FabulousSuccess() {
      this.getOneComment(1, 1, this.dataForm.fk_course_id, 0);
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-textarea__inner {
  resize: none;
}
::v-deep .el-textarea__inner {
  border: none;
  padding: 0;
}
.bgc {
  width: 1920px;
  height: 1500px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
}
.kong {
  width: 100%;
  margin: 0 auto;
}
.comment {
  width: 1200px;
  margin: 0 auto;
  .logo-box {
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 20px;
    .logo-img {
      width: 100%;
      height: 100%;
    }
  }
  .user-comment {
    display: flex;
    width: 100%;
    padding-bottom: 30px;
    .comment-input-box {
      flex: 1;
      height: 115px;
      padding: 18px;
      display: flex;
      font-size: 14px;

      font-weight: 400;
      color: #666666;
      border: 1px solid #f3f3f4;
      border-radius: 4px;
      .tip {
        padding-top: 7px;
      }
      .login-btn {
        width: 70px;
        height: 30px;
        border-radius: 15px;
        background: #0086ff;
        font-size: 14px;
        cursor: pointer;
        font-weight: 400;
        color: #ffffff;
        margin-right: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
  .right-input-box {
    width: 100%;
    height: 115px;
    border: 1px solid #f3f3f4;
    border-radius: 4px;
    padding: 14px;
    display: flex;
    flex-direction: column;
    .input-btn-box {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: relative;
      .btn-icon {
        width: 19px;
        height: 19px;
        cursor: pointer;
      }
      .bq {
        position: absolute;
        left: -16px;
        bottom: -425px;
        z-index: 100;
      }
      .input-btn {
        width: 70px;
        height: 30px;
        background: #0086ff;
        border-radius: 15px;
        font-size: 14px;
        cursor: pointer;
        font-weight: 400;
        color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
  .comment-list {
    width: 100%;
    display: flex;
    flex-direction: column;
    .comment-item {
      flex: 1;
      display: flex;
      padding-bottom: 22px;
      padding-top: 10px;
      border-bottom: 1px solid #f3f3f4;
      .item-right {
        width: 100%;
        display: flex;
        flex-direction: column;
        .info-box {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding-bottom: 16px;
          .user-box {
            display: flex;
            align-items: flex-end;
            .user-name {
              font-size: 16px;

              font-weight: 400;
              color: #999999;
              padding-right: 12px;
            }
            .user-time {
              font-size: 12px;

              font-weight: 400;
              color: #999999;
            }
          }
          .operation-box {
            display: flex;
            align-items: center;
            font-size: 16px;

            font-weight: 400;
            color: #999999;
            .operation-icon {
              width: 16px;
              height: 16px;
              margin-right: 8px;
            }
            .operation-comment-box {
              display: flex;
              align-items: center;
              margin-right: 20px;
              cursor: pointer;
            }
            .operation-num-box {
              display: flex;
              align-items: center;
              cursor: pointer;
            }
          }
        }
        .comment-content {
          width: 100%;
          font-size: 16px;

          font-weight: 400;
          color: #666666;
          padding-bottom: 24px;
        }
      }
    }
  }
  .pagination {
    padding: 58px 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
