<template>
  <div class="detail" v-loading="initloading">
    <div class="video-box" v-if="init">
      <div class="video-content">
        <div class="content-title">
          <span>当前位置：</span>
          <div class="title-list">
            <div class="title-item">首页</div>
            <img class="title-icon" src="@/assets/teacherTraining/ic_13.png" />
            <div class="title-item">
              {{ dataForm.type == 1 ? "教育资源" : "云端直播" }}
            </div>
            <img class="title-icon" src="@/assets/teacherTraining/ic_13.png" />
            <div class="title-item">内容详情</div>
          </div>
        </div>
        <div class="content-video">
          <div class="video-play">
            <div class="video">
              <div
                class="play-show"
                v-if="dataForm.type != 1 && dataForm.live_status != 3"
              >
                <img class="video-play-img" :src="dataForm.img" alt="" />
                <div class="mask">
                  <div class="zbcode" v-if="dataForm.live_status == 2">
                    <div class="zbcode-icon-box">
                      <img class="zbcode-icon" :src="dataForm.cdn_url" alt="" />
                    </div>
                    <div class="zbcode-title">请扫码观看线上观看会议直播</div>
                  </div>
                  <div class="yg-box" v-else>
                    <div class="zbcode-icon-box">
                      <img
                        class="zbcode-icon"
                        v-if="dataForm.cdn_url"
                        :src="dataForm.cdn_url"
                        alt=""
                      />
                    </div>
                    <div class="yg-tip">
                      「预告」{{ dataForm.start_time }}开播
                    </div>
                    <div class="yg-time">
                      <span>距离开播还剩</span>
                      <span class="time-num">{{ timeObj.days }}</span>
                      <span>天</span>
                      <span class="time-num">{{ timeObj.hours }}</span>
                      <span>时</span>
                      <span class="time-num">{{ timeObj.minutes }}</span>
                      <span>分</span>
                      <span class="time-num">{{ timeObj.seconds }}</span>
                      <span>秒</span>
                    </div>
                  </div>
                </div>
              </div>
              <div v-else>
                <template v-if="ChapterList[0]">
                  <video
                    v-if="
                      ChapterList[0] && ChapterList[chapterCurrent].file_path
                    "
                    style="object-fit: cover"
                    :src="ChapterList[chapterCurrent].file_path"
                    autoplay
                    width="1200px"
                    height="673px"
                    :onplaying="onPlay()"
                    controls
                  ></video>
                </template>
                <div style="width: 100%; height: 100%" v-else>
                  <img
                    style="height: 100%; width: 100%"
                    :src="dataForm.img"
                    alt=""
                  />
                </div>
              </div>
              <div :class="['switch-box', showVideo ? 'active' : '']">
                <div class="switch">
                  <img
                    class="switch-icon-open"
                    src="@/assets/teacherTraining/ic_7.png"
                    alt=""
                    @click="showList"
                  />
                  <div class="switch-list">
                    <div class="switch-list-title">课时章节</div>
                    <div class="chapter-list">
                      <div
                        :class="[
                          'chapter-item',
                          index == chapterCurrent ? 'active' : '',
                        ]"
                        v-for="(item, index) in ChapterList"
                        :key="index"
                        @click="changchapter(index)"
                      >
                        <img
                          v-if="playing && index == chapterCurrent"
                          class="chapter-icon"
                          src="https://eduapi.114kaili.com/Content/images/player_ico.gif"
                          alt=""
                        />
                        <img
                          v-else
                          class="chapter-icon"
                          src="@/assets/teacherTraining/ic_8.png"
                          alt=""
                        />
                        <span>{{ item.name }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <img
              v-show="!showVideo"
              class="switch-icon"
              src="@/assets/teacherTraining/ic_6.png"
              alt=""
              @click="showList"
            />
          </div>
          <div class="operation">
            <div class="operation-left">
              <span class="viewNum">阅读量：{{ dataForm.read_num }}</span>
              <span>评论数：{{ dataForm.comment_count }}</span>
            </div>
            <div class="operation-right">
              <div
                :class="[
                  'fabulous',
                  dataForm.fabulous_status == 1 ? 'active-fabulous' : '',
                ]"
                @click="onFabulous(1, 1, dataForm.fk_course_id)"
              >
                <img
                  class="fabulous-icon"
                  src="@/assets/teacherTraining/ic_4_fill.png"
                  alt=""
                  v-if="dataForm.fabulous_status == 1"
                />
                <img
                  v-else
                  class="fabulous-icon"
                  src="@/assets/teacherTraining/ic_4.png"
                  alt=""
                />
                <span>点赞（{{ dataForm.fabulous_count }}）</span>
              </div>
              <div
                :class="[
                  'collection',
                  dataForm.collect_status == 1 ? 'active-fabulous' : '',
                ]"
                @click="onFabulous(2, 1, dataForm.fk_course_id)"
              >
                <img
                  class="collection-icon"
                  src="@/assets/teacherTraining/ic_5_fill.png"
                  alt=""
                  v-if="dataForm.collect_status == 1"
                />
                <img
                  v-else
                  class="collection-icon"
                  src="@/assets/teacherTraining/ic_5.png"
                  alt=""
                />
                <span>收藏</span>
              </div>
            </div>
          </div>
        </div>
        <div class="detail-info">
          <div class="info-title line-2">
            {{ dataForm.name }}
          </div>
          <div class="info-introduction line-2">
            课程简介：{{ dataForm.remark }}
          </div>
        </div>
      </div>
    </div>
    <div
      v-else
      style="width: 100%; height: 700px; background-color: #191a20"
    ></div>
    <div class="content-box">
      <div class="box-center">
        <div class="center-title-box">
          <div
            :class="['leaving', index == current ? 'active' : '']"
            v-for="(item, index) in navList"
            @click="change(index)"
            :key="index"
          >
            <span>{{ item.name }}</span>
            <span class="leavingNum" v-if="item.num > 0">{{ item.num }}</span>
          </div>
        </div>
        <div class="center-content">
          <comment :dataForm="dataForm" v-show="current == 0" />
          <base-info :content="dataForm.content" v-show="current == 1" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import baseInfo from "./component/base-info";
import comment from "./component/comment";
import detail from "@/mixins/detail.js";
import moment from "moment";
import Api from "@/api";
export default {
  components: {
    baseInfo,
    comment,
  },
  mixins: [detail],
  data() {
    return {
      current: 0,
      init: false,
      initloading: true,
      navList: [
        {
          name: "留言",
          num: "1000",
        },
        {
          name: "详情",
          num: "0",
        },
      ],
      chapterCurrent: 0,
      showVideo: false,
      searchForm: {
        fk_course_id: "",
      },
      dataForm: {},
      timer: "",
      timeObj: {
        days: "",
        hours: "",
        minutes: "",
        seconds: "",
      },
      ChapterList: [],
      playing: false,
    };
  },
  created() {
    if (this.$route.query.id) {
      this.searchForm.fk_course_id = this.$route.query.id;
    }
    this.initPage();
    this.CourseChapter();
  },
  methods: {
    initPage() {
      Api.CourseDetail({
        fk_user_id: this.userInfo.fk_user_id || 0,
        ...this.searchForm,
      }).then((res) => {
        if (res.status == 1) {
          this.dataForm = res.data;
          this.navList[0].num = res.data.comment_count;
          if (res.data.type != 1 && res.data.live_status != 2) {
            this.getTime();
          }
          this.init = true;
          this.initloading = false;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    onPlay() {
      this.playing = true;
    },
    formatNum(num) {
      if (num.length <= 2) {
        return (num = "0" + num);
      } else {
        return num;
      }
    },
    getTime() {
      this.timer = setTimeout(() => {
        let star = moment(new Date());
        let end = moment(new Date(this.dataForm.start_time));
        let diff = end.diff(star);
        if (diff <= 0) {
          clearTimeout(this.timer);
          this.timeObj.days = "00";
          this.timeObj.hours = "00";
          this.timeObj.minutes = "00";
          this.timeObj.seconds = "00";
          return;
        }
        this.timeObj.days = this.formatNum(moment.duration(diff).days());
        this.timeObj.hours = this.formatNum(moment.duration(diff).hours());
        this.timeObj.minutes = this.formatNum(moment.duration(diff).minutes());
        this.timeObj.seconds = this.formatNum(moment.duration(diff).seconds());
        this.getTime();
      }, 1000);
    },
    CourseChapter() {
      Api.CourseChapter(this.searchForm).then((res) => {
        if (res.status == 1) {
          this.ChapterList = res.data || [];
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    changchapter(idx) {
      this.chapterCurrent = idx;
    },
    FabulousSuccess() {
      this.initPage();
    },
    change(idx) {
      this.current = idx;
    },
    showList() {
      this.showVideo = !this.showVideo;
    },
  },
};
</script>

<style lang="scss" scoped>
.detail {
  width: 100%;
  .video {
    width: 100%;
    height: 100%;
  }
  .active-fabulous {
    color: #ff8d02;
  }
  .video-box {
    width: 100%;
    background-color: #191a20;
    height: 885px;
    display: flex;
    justify-content: center;
    padding-top: 30px;
    .video-content {
      width: 1200px;
      .content-title {
        flex: 1;
        display: flex;
        align-items: center;
        font-size: 16px;

        font-weight: 400;
        color: #ffffff;
        padding-bottom: 18px;
        .title-list {
          display: flex;
          align-items: center;
          .title-icon {
            width: 14px;
            height: 14px;
            margin: 0 5px;
          }
        }
      }
      .content-video {
        width: 100%;
        .video-play {
          width: 100%;
          height: 673px;
          position: relative;
          .video {
            width: 100%;
            height: 100%;
            overflow: hidden;
            position: relative;
          }
          .play-show {
            width: 100%;
            height: 100%;
          }
          .video-play-img {
            width: 100%;
            height: 100%;
          }
          .zbcode-icon-box {
            width: 298px;
            height: 298px;
            padding: 20px;
            background-color: #fff;
            margin-bottom: 30px;
            .zbcode-icon {
              width: 100%;
              height: 100%;
            }
          }

          .mask {
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.3);
            position: absolute;
            top: 0;
            left: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            .zbcode {
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              font-size: 24px;
              font-family: Source Han Sans CN;
              font-weight: 400;
              color: #ffffff;
            }
            .yg-box {
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              font-size: 18px;
              font-weight: 400;
              color: #ffffff;
              .yg-time {
                padding-top: 10px;
                display: flex;
                align-items: center;
                .time-num {
                  color: #ff8d02;
                }
              }
            }
          }
          .switch-icon {
            width: 20px;
            height: 70px;
            position: absolute;
            top: 50%;
            transform: translateX(-50%);
            right: -30px;
            cursor: pointer;
            z-index: 100;
          }
          .switch-box {
            position: absolute;
            top: 0;
            right: 0;
            height: 100%;
            width: 390px;
            display: flex;
            transform: translateX(100%);
            transition: all 0.6s ease;
            &.active {
              transform: translateX(0);
              transition: all 0.6s ease;
            }
            .switch {
              width: 100%;
              height: 100%;
              position: relative;
              .switch-icon-open {
                width: 20px;
                height: 70px;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                left: 0;
                cursor: pointer;
              }
            }
            .switch-list {
              width: 390px;
              height: 100%;
              background: #2f2f2f;
              .switch-list-title {
                font-size: 18px;

                font-weight: 400;
                color: #ffffff;
                height: 60px;
                padding-left: 42px;
                display: flex;
                align-items: center;
              }
              .chapter-list {
                flex: 1;
                display: flex;
                flex-direction: column;
                z-index: 100;
                .chapter-item {
                  width: calc(100% - 42px);
                  height: 60px;
                  display: flex;
                  align-items: center;
                  font-size: 14px;
                  cursor: pointer;
                  font-weight: 400;
                  color: #ffffff;
                  margin-left: 30px;
                  padding-left: 12px;
                  &.active {
                    background-color: rgba(255, 255, 255, 0.1);
                  }
                  .chapter-icon {
                    width: 16px;
                    height: 16px;
                    margin-right: 10px;
                  }
                }
              }
            }
          }
        }

        .operation {
          flex: 1;
          display: flex;
          padding: 16px 18px;
          align-items: center;
          justify-content: space-between;
          background-color: #222328;
          .operation-left {
            display: flex;
            align-items: center;
            font-size: 16px;

            font-weight: 400;
            color: #999999;
            .viewNum {
              padding-right: 20px;
            }
          }
          .operation-right {
            display: flex;
            align-items: center;
            font-size: 16px;

            font-weight: 400;
            color: #999999;
            .fabulous {
              display: flex;
              align-items: center;
              margin-right: 20px;
              cursor: pointer;
              .fabulous-icon {
                width: 16px;
                height: 16px;
                margin-right: 9px;
              }
            }
            .collection {
              display: flex;
              align-items: center;
              cursor: pointer;
              .collection-icon {
                width: 18px;
                height: 16px;
                margin-right: 9px;
              }
            }
          }
        }
      }
      .detail-info {
        width: 100%;
        display: flex;
        flex-direction: column;
        padding: 20px 0;
        .info-title {
          font-size: 24px;

          font-weight: 400;
          color: #ffffff;
          padding-bottom: 20px;
        }
        .info-introduction {
          font-size: 16px;

          font-weight: 400;
          color: #b9b9bb;
        }
      }
    }
  }
  .content-box {
    width: 100%;
    background-color: #fff;
    display: flex;
    justify-content: center;
    .box-center {
      width: 1200px;
      .center-title-box {
        flex: 1;
        display: flex;
        align-items: center;
        color: #999999;
        padding: 50px 0 14px;
        border-bottom: 1px solid #f3f3f4;

        .leaving {
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 90px;
          font-size: 26px;
          cursor: pointer;
          font-weight: 400;
          &.active {
            color: #1d2088;
          }
          &.active::after {
            content: "";
            width: 50px;
            height: 2px;
            background-color: #1d2088;
            position: absolute;
            left: 0;
            bottom: -15px;
          }
          .leavingNum {
            font-size: 12px;
            position: absolute;
            top: 0;
            right: -30px;
          }
        }
      }
      .center-content {
        width: 100%;
        padding: 40px 0 60px;
      }
    }
  }
}
</style>
